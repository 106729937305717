import React from 'react'
import theme from '../../theme'
import { createUseStyles } from 'react-jss'
import { ReactComponent as Background } from '../../assets/top-bg.svg'
import { ReactComponent as Rocket } from '../../assets/rocket.svg'
import { ReactComponent as Moon } from '../../assets/moon-bg.svg'
import { ReactComponent as BackgroundWhiteWave } from '../../assets/top_white_bg_editted.svg'

function Top(props) {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <Background className={classes.background} />
      <Moon className={classes.moon} />
      <Rocket className={classes.rocket} />
      <div className={classes.container}>
        <div className={classes.leftPanel}>
          <div className={classes.header}>SWITCHEO REFERRAL PROGRAM</div>
          <div className={classes.title}>Invite Your Friends.<br></br>Earn 50% Of Their Trading Fees.<br></br></div>
          <a href="https://switcheo.exchange" target="_blank" rel="noopener noreferrer" className={classes.button}>Invite Now</a>
        </div>
        <div className={classes.rightPanel} />
      </div>
      <BackgroundWhiteWave className={classes.bottomBackground} />
    </div>
  )
}

const useStyles = createUseStyles({
  background: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    '@media (max-width: 979px)': {
      height: '22rem',
    },
  },
  bottomBackground: {
    position: 'absolute',
    bottom: '-1px',
    width: '100%',
    height: 'auto',
  },
  main: {
    position: 'relative',
    width: '100vw',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0,
    background: '#51608C',
    '@media (max-width: 979px)': {
      height: '37rem',
    },
  },
  container: {
    position: 'absolute',
    width: 1320,
    top: 0,
    margin: '12rem auto 4rem auto',
    color: theme.fontLight,
    display: 'flex',
    justifyContent: 'space-around',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '95vw',
      margin: '8rem auto 4rem auto',
    },
  },
  leftPanel: {
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '60%',
    justifyContent: 'center',
    '@media (max-width: 979px)': {
      width: '88vw',
    },
  },
  rightPanel: {
    zIndex: 1,
    width: '38vw',
    height: '25vw',
    '@media (min-width: 1024px)': {
      width: '33vw',
    },
    '@media (max-width: 479px)': {
      display: 'none',
    },
  },
  header: {
    fontSize: '2.2rem',
    textTransform: 'uppercase',
    fontWeight: 500,
    letterSpacing: 0.4,
    opacity: 0.9,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '2.0rem',
    },
    '@media (max-width: 979px)': {
      fontSize: '1.6rem',
    },
  },
  title: {
    fontSize: '4.6rem',
    fontWeight: 600,
    letterSpacing: 1,
    textAlign: 'left',
    minWidth: '80rem',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '3.8rem',
      minWidth: 'auto',
    },
    '@media (max-width: 979px)': {
      fontSize: '2.2rem',
      minWidth: 'auto',
    },
  },
  button: {
    zIndex: 1,
    backgroundImage: 'linear-gradient(76deg, #65aa88, #7cc0d3)',
    color: theme.fontLight,
    width: '24rem',
    height: '5rem',
    borderRadius: 4,
    fontSize: '1.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    cursor: 'pointer',
    margin: '2rem 0',
    letterSpacing: 1,
    textDecoration: 'none',
    '@media (max-width: 979px)': {
      width: '18rem',
      height: '4.2rem',
    },
  },
  findCode: {
    zIndex: 1,
    fontSize: '1.6rem',
    '@media (max-width: 979px)': {
      fontSize: '1.2rem',
    },
  },
  moon: {
    position: 'absolute',
    right: '1vw',
    bottom: '-22vw',
    height: '40vw',
    width: 'auto',
  },
  rocket: {
    position: 'absolute',
    right: '1vw',
    bottom: '1vw',
    height: '36vw',
    width: 'auto',
  },
  bitcoin: {
    margin: '0',
    width: 36,
    height: 36,
    verticalAlign: 'middle',
    '@media (max-width: 979px)': {
      width: 26,
      height: 26,
    },
  },
})

export default Top