import React, { useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import $ from 'jquery'
import { faTelegramPlane, faTwitter,
  faRedditAlien, faMedium, faLinkedinIn, faFacebookF, faYoutube, faGithubAlt } from '@fortawesome/free-brands-svg-icons'
import './footer.scss'
import './header.scss'


function Layout(props) {
  const { children } = props
  const classes = useStyles()
  useEffect(() => {
    $('.header-component #nav-menu').click(function onClick() {
      $('.header-component header nav').toggleClass('active')
      $(this).toggleClass('active')
    })

    if ($(window).scrollTop() > 0) {
      $('header').addClass('solid')
    }

    $(window).on('scroll', () => {
      if ($(window).scrollTop() >= 20) {
        $('header').addClass('solid')
      } else if ($(window).scrollTop() < 20) {
        $('header').removeClass('solid')
      }
    })
  }, [])
  return (
    <div className={classes.global}>
      <div className="header-component">
        <header>
          <a href="https://switcheo.network" className="logo">logo</a>
          <div id="nav-menu">
            <span />
            <span />
          </div>
          <nav>
            <ul>
              <li>
                <a href="https://switcheo.network">Home</a>
              </li>
              <li>
                <a href="https://switcheo.exchange/">Exchange</a>
              </li>
              <li>
                <a href="https://switcheo.network/about.html">About Us</a>
              </li>
              <li>
                <a href="https://switcheo.network/referral">Referral</a>
              </li>
              <li>
                <a href="https://blog.switcheo.network">Blog</a>
              </li>
              <li>
                <a href="https://switcheo.network/events.html">Events</a>
              </li>
              <li>
                <a href="https://support.switcheo.network">Support</a>
              </li>
            </ul>
          </nav>
        </header>
      </div>
      <div className={classes.body}>
        {children}
      </div>
      <div className="footer-component">
        <footer>
          <div className="section-wrap clearfix">
            <div className="sitemap">
              <h3>Sitemap</h3>
              <ul>
                <li>
                  <a href="https://switcheo.network/index.html">Home</a>
                </li>
                <li>
                  <a href="https://switcheo.exchange">Exchange</a>
                </li>
                <li>
                  <a href="https://switcheo.network/about.html">About Us</a>
                </li>
                <li>
                  <a href="https://switcheo.network/referral">Referral</a>
                </li>
                <li>
                  <a href="https://blog.switcheo.network">Blog</a>
                </li>
                <li>
                  <a href="https://switcheo.network/events.html">Events</a>
                </li>
                <li>
                  <a href="https://support.switcheo.network">Support</a>
                </li>
              </ul>
            </div>
            <div className="sitemap quicklinks">
              <h3>Resources</h3>
              <ul>
                <li>
                  <a href="https://hackerone.com/switcheo_network">Security</a>
                </li>
                <li>
                  <a href="https://switcheo.network/whitepaper_v1.pdf">Whitepaper</a>
                </li>
                <li>
                  <a href="https://docs.switcheo.network/">API Docs</a>
                </li>
                <li>
                  <a href="https://switcheo.network/switcheo_mediakit.pdf">Media Kit</a>
                </li>
                <li>
                  <a href="https://switcheo.network/terms.html">Terms of Use</a>
                </li>
                <li>
                  <a href="https://switcheo.network/privacy.html">Privacy Policy</a>
                </li>
              </ul>
            </div>
            <div className="footer-right-wrap">
              <div className="sign-up-wrap">
                <h3 className="mini-text">Stay in touch with our latest news</h3>
                <div
                  id="sendgrid-subscription-widget"
                  className="sendgrid-subscription-widget"
                  data-emailerror="Please enter a valid email address"
                  data-nameerror="Please enter your name"
                  data-checkboxerror="Please tick the box to accept our conditions"
                >
                  <form id="sg-widget" data-token="52447a454fad5b9fe4973c939fbae918" onSubmit={() => false}>
                    <div className="sg-response" id="sg-response" />
                    <input
                      id="sg_email"
                      type="email"
                      name="sg_email"
                      placeholder="john@example.com"
                      required="required"
                    />
                    <input className="switcheo-cta-btn" type="submit" id="sg-submit-btn" value="&rarr;" />
                    <div className="sg-consent-text">
                      <label htmlFor="sg_consent_checkbox">
                        <input type="checkbox" id="sg_consent_checkbox" name="sg_consent_checkbox" />
                        <span className="checkmark" />
                        I understand and consent to the following <a target="_blank" href="./privacy.html">Privacy Notice</a>
                      </label>
                    </div>
                  </form>
                </div>
                <div className="social">
                  <h3>Follow us</h3>
                  <ul>
                    <li>
                      <a href="https://github.com/switcheo"><FontAwesomeIcon icon={faGithubAlt} /></a>
                    </li>
                    <li>
                      <a href="https://t.me/switcheo"><FontAwesomeIcon icon={faTelegramPlane} /></a>
                    </li>
                    <li>
                      <a href="https://twitter.com/switcheonetwork"><FontAwesomeIcon icon={faTwitter} /></a>
                    </li>
                    <li>
                      <a href="https://www.reddit.com/r/Switcheo/"><FontAwesomeIcon icon={faRedditAlien} /></a>
                    </li>
                    <li>
                      <a href="https://medium.com/switcheo"><FontAwesomeIcon icon={faMedium} /></a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/switcheonetwork/">
                        <FontAwesomeIcon icon={faLinkedinIn} />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.facebook.com/SwitcheoExchange/"><FontAwesomeIcon icon={faFacebookF} /></a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/channel/UCqZ9sxvw-0UoHzno4-d97oQ/playlists">
                        <FontAwesomeIcon icon={faYoutube} />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <p className="copyright mini-text">&copy; • 2019 Switcheo Exchange Pte Ltd</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  '@global': {
    html: {
      // overscrollBehavior: 'none',
      // overflow: 'hidden',
      fontSize: '62.5%', // 62.5% == 10px on standard browsers (16px is default font-size)
      '@media (max-width: 1439px)': { // medium desktop
        fontSize: '56.25%', // 9px on medium browsers
      },
    },
    'div': {
      // overscrollBehavior: 'none',
      // overflow: 'hidden',
      boxSizing: 'border-box',
    },
    'a': {
      // overscrollBehavior: 'none',
      // overflow: 'hidden',
      color: '#88c773',
      textDecoration: 'none',
    },
  },
  global: {
    fontFamily: 'Poppins',
  },
  body: {
    // overflowY: 'auto',
    background: theme.background,
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    position: 'fixed',
    display: 'flex',
    width: '100vw',
    maxWidth: '100%',
    zIndex: 2,
    borderBottom: '2px solid rgba(166, 179, 217, 0.2)',
  },
  logo: {
    width: '12rem',
    padding: '1rem',
  },
  leftNav: {
    flexGrow: 1,
    textAlign: 'left',
  },
  rightNav: {
    flexGrow: 0,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    padding: '1rem 1rem',
    margin: '0 0.3rem',
    textDecoration: 'none',
    color: theme.fontLight,
    transition: 'all 1s',
    fontSize: '0.9rem',
    letterSpacing: 1,
    fontWeight: 600,
    '&:hover': {
      color: theme.fontHighlight,
    },
  },
})

export default Layout