import React from 'react'
import theme from '../theme'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

function FAQCard(props) {
  const classes = useStyles()
  const { q, a, onClick, isOpen } = props
  return (
    <div className={classes.container}>
      <div className={classes.question} onClick={onClick}>
        <span className={classes.questionText}>{q}</span>
        <div className={classes.icon}>
          <div className={classNames(classes.vertical, { isOpen })} />
          <div className={classes.horizontal} />
        </div>
      </div>
      {isOpen && (<div className={classes.answer}>
        <div>
          {a === 'when-rewards' ? (
            <div>
              <div className={classes.answerHeader}>On-going Rewards</div>
              <div>Your rebates will be accumulated in your Portfolio where you will be able to withdraw upon a min. balance of USD$25. We will conduct a batch withdrawal process, with your rewards airdropped directly into your wallet balance at the end of the month.</div>
              <div className={classes.secondAnswerHeader}>Exclusive Rewards</div>
              <div>At the end of the promotional period, we will contact all winners via our official channels and rewards will be airdropped directly into your wallet balance within a month.</div>
            </div>
          ) : null}
          {a === 'what-rewards' ? (
            <div>
              <div className={classes.answerHeader}>On-going Rewards</div>
              <div>Every successful trade from a referred friend earns you 50% rebates off their discounted trading fees. Limited to the first 3 months of trade.</div>
              <div>Every successfully referred friend will be entitled to a 25% discount on their trading fees instantly. Limited to the first 3 months of trade.</div>
              <div className={classes.secondAnswerHeader}>Exclusive Rewards</div>
              <div>Each successful referral earns you a ticket towards an exclusive draw pool. At the end of every 3 months, the more tickets you earn, the greater your chances are of winning from our giveaway pool of $40,000 Bitcoin (BTC)!</div>
            </div>
          ) : null}
          {a !== ('what-rewards') && a !== ('when-rewards') ? a : null}
        </div>
      </div>)}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    background: 'rgba(118, 145, 203, 0.2)',
    borderRadius: 4,
    marginTop: '1.6rem',
    width: 1320,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '95vw',
    },
  },
  question: {
    height: '6rem',
    color: theme.fontLight,
    fontSize: '1.8rem',
    display: 'flex',
    alignItems: 'center',
    padding: '0 3rem',
    letterSpacing: 0.7,
    justifyContent: 'space-between',
    cursor: 'pointer',
    textAlign: 'left',
    '@media (max-width: 979px)': {
      fontSize: '1.4rem',
    },
  },
  questionText: {
    '@media (max-width: 979px)': {
      width: '230px',
    },
  },
  icon: {
    height: '2rem',
    width: '2rem',
    position: 'relative',
  },
  horizontal: {
    position: 'absolute',
    width: '2rem',
    height: '0.3rem',
    backgroundImage: 'linear-gradient(45deg, #65aa88, #7cc0d3)',
    borderRadius: 4,
    top: '50%',
    left: 0,
    transform: 'translate(0, -50%)',
  },
  vertical: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 0)',
    height: '2rem',
    width: '0.3rem',
    backgroundImage: 'linear-gradient(45deg, #65aa88, #7cc0d3)',
    borderRadius: 4,
    '&.isOpen': {
      display: 'none',
    },
  },
  answer: {
    background: theme.background,
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    color: theme.fontDark,
    padding: '3rem 3rem',
    textAlign: 'left',
    fontSize: '1.6rem',
    '@media (max-width: 979px)': {
      fontSize: '1.4rem',
    },
  },
  answerHeader: {
    fontWeight: 600,
  },
  secondAnswerHeader: {
    fontWeight: 600,
    marginTop: '1rem',
  },
})

export default FAQCard