import React, { useState } from 'react'
import theme from '../../theme'
import { createUseStyles } from 'react-jss'
import { ReactComponent as Background } from '../../assets/bottom_bg.svg'
import FAQCard from '../FAQCard'

const defaultState = [false, false, false, false, false]

function Bottom() {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(defaultState)

  function onOpen(int) {
    let state = [false, false, false, false, false]
    const selectedFAQ = !isOpen[int]
    state[int] = selectedFAQ
    setIsOpen(state)
  }

  return (
    <div className={classes.main} id='faq-section'>
      <Background className={classes.background}/>
      <div className={classes.container}>
        <div className={classes.header}><b>Frequently Asked Questions</b></div>
        <FAQCard onClick={() => onOpen(0)}
                 isOpen={isOpen[0]}
                 q="Who is eligible for Switcheo’s Referral Program?"
                 a={
                   <div>This program is open to Switcheo users with a <a
                     href="https://intercom.help/switcheonetwork/en/articles/2758576-creating-a-switcheo-account">Switcheo
                     Account</a> or wallet users that have created a <a
                     href="https://intercom.help/switcheonetwork/en/articles/3429193-create-a-switcheo-portfolio">Switcheo
                     Portfolio</a>.</div>
                 }/>
        <FAQCard onClick={() => onOpen(1)}
                 isOpen={isOpen[1]}
                 q="What is a Switcheo Portfolio?"
                 a={<div><b>Switcheo Portfolio</b> is an initiative which allows users to participate in referral
                   programs and reward campaigns using their existing pseudo-anonymous wallet addresses. It will also
                   allow users to track their various trading statistics (such as PnL) in the near future.</div>}/>
        <FAQCard onClick={() => onOpen(2)} isOpen={isOpen[2]}
                 q="How do I create a Switcheo Portfolio?"
                 a={<div>
                   There are two ways to gain access to Switcheo Portfolio on the exchange:

                   <ul>
                     <li>Creating and logging in to a Switcheo Account</li>
                     <li>Connecting a 3rd-party blockchain wallets (e.g: MetaMask) and creating a Switcheo Portfolio
                     </li>
                   </ul>

                   Note that existing Switcheo Account users <b>do not</b> need to create a new portfolio, as it will be
                   automatically created for you. For users using 3rd-party blockchain wallets, find out how to create
                   a Portfolio here.
                 </div>
                 }/>
        <FAQCard onClick={() => onOpen(3)} isOpen={isOpen[3]}
                 q="What are the benefits for me as a referrer?"
                 a={<div>
                   Every trade from a friend that you referred earns you <b>50%</b> of their trading fees. This is
                   capped to the first 3 months of trades your friend does, starting from the successful sign up with
                   your referral code.
                 </div>
                 }/>
        <FAQCard onClick={() => onOpen(4)} isOpen={isOpen[4]}
                 q="How will I receive my referral benefits?"
                 a={<div>
                   Your bonuses will be accrued in your Portfolio and will only be paid out with a minimum balance of
                   USD25.<br/><br/>

                   Bonuses will be distributed to you in the blockchain’s native network token:

                   <ul>
                     <li>Ethereum blockchain - ETH</li>
                     <li>NEO blockchain - GAS</li>
                   </ul>

                   To receive the bonuses, you will need to have the appropriate blockchain wallets linked to your
                   Portfolio. Switcheo Account users already have all blockchain wallets linked by default.<br/><br/>

                   Bonuses will be sent directly to the wallets linked to your Portfolio at the end of each quarter.
                 </div>
                 }/>
        <FAQCard onClick={() => onOpen(5)} isOpen={isOpen[5]}
                 q="What benefits will my friends receive?"
                 a={
                   <div>Your invited friends will receive a <b>25% discount</b> on their trading fees for the first 3
                     months on all trades.</div>
                 }/>
        <FAQCard onClick={() => onOpen(6)} isOpen={isOpen[6]}
                 q="How will I know when I have successfully referred someone?"
                 a={
                   <div>
                     Each successful referral is automatically tracked in your Portfolio page in your Wallet Manager.
                     You can also track your referral bonuses and tickets earned all in one place.<br/><br/>

                     <i>Disclaimer: Switcheo may revoke any bonuses or tickets if we notice any activity that we believe
                       is abusive or fraudulent. We reserve the right to review and investigate all referral activities
                       and to disqualify users or modify referral counts as we deem fair and appropriate.</i>
                   </div>
          } />
        <FAQCard onClick={() => onOpen(7)} isOpen={isOpen[7]}
          q="Do I get referral bonuses from burnt tokens?"
          a={
            <div>No</div>
          }/>
        <FAQCard onClick={() => onOpen(8)} isOpen={isOpen[181]}
                 q="When does the referral program end?"
                 a="The Switcheo Referral Program will end on 29 October 2020, 18:00 SGT (UTC+8)."/>
        <FAQCard onClick={() => onOpen(9)} isOpen={isOpen[9]}
                 q="What do I do if I have more questions?"
                 a={
                   <div>
                     You can contact our support team on <a href="https://t.me/switcheo">Telegram</a> or send us an
                     email to support@switcheo.network.
                   </div>
                 }/>
        <a href="https://switcheo.network/pdf/referral-terms.pdf" target = "_blank" rel="noopener noreferrer" className={classes.tandc} id='tnc-section'>Terms & Conditions</a>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  main: {
    width: '100vw',
    maxWidth: '100%',
    marginTop: '5rem',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#475582',
    '@media (max-width: 979px)': {
      marginTop: '4rem',
    },
  },
  background: {
    background: theme.background,
    width: '100vw',
    maxWidth: '100%',
    position: 'absolute',
    top: 0,
    height: 'auto',
  },
  container: {
    width: 1320,
    margin: '8rem auto 1rem auto',
    display: 'flex',
    flexDirection: 'column',
    color: theme.fontLight,
    zIndex: 1,
    minHeight: '88rem',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '95vw',
      minHeight: 'auto',
    },
  },
  header: {
    fontSize: '3rem',
    textTransform: 'uppercase',
    margin: '8rem 0 7rem 0',
    '@media (max-width: 979px)': {
      margin: '0rem 0 2rem 0',
    },
  },
  tandc: {
    margin: '6rem 0',
    // color: '#8d9ec9',
    fontSize: '1.6rem',
  },
})

export default Bottom