
import React from 'react'
import Top from './Top'
import Second from './Second'
import Bottom from '../landing-page/Bottom'

function ReferredBy(props) {
  return (
    <React.Fragment>
      <Top id={props.id} />
      <Second />
      <Bottom />
    </React.Fragment>
  )
}

export default ReferredBy