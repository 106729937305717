
import React from 'react'
import Top from './Top'
import Second from './Second'
import Third from './Third'
import Bottom from './Bottom'

function Home() {
  return (
    <React.Fragment>
      <Top />
      <Second />
      <Third />
      <Bottom />
    </React.Fragment>
  )
}

export default Home