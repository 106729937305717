import React from 'react'
import theme from '../../theme'
import { ReactComponent as Background } from '../../assets/center_bg.svg'
import Card from '../Card'
import { createUseStyles } from 'react-jss'
import { ReactComponent as How } from '../../assets/how-card-bg.svg'
import { ReactComponent as HowConnect } from '../../assets/how_connect.svg'
import { ReactComponent as HowTell } from '../../assets/how_tell.svg'
import { ReactComponent as HowEarn } from '../../assets/how_earn.svg'

function MediumCard(props) {
  const { classes, cta, des, header } = props
  return (
  <Card className={classes.mediumCard}>
    <How className={classes.howBackground} />
    <React.Fragment>
      <props.howImage className={classes.howIcon} />
    </React.Fragment>
    <div className={classes.howContent}>
      <div className={classes.howHeader}>{header}</div>
      <div className={classes.howCta}>{cta}</div>
      <div className={classes.howDescription}>{des}</div>
    </div>
  </Card>
  )
}

function Third() {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <Background className={classes.background} />
      <div className={classes.container}>
        <div className={classes.discountCard}>
          <Card className={classes.card}>
            <div className={classes.cardHeader}>You Get</div>
            <div className={classes.discountNumber}>50</div>
            <div className={classes.cardSmallText}>
              <div className={classes.cardRightTextTop}>% BONUS</div>
              <div className={classes.cardVerySmallText}>of each friend’s trading fees</div>
            </div>
          </Card>
          <Card className={classes.cardTwo}>
            <div className={classes.cardHeaderTwo}>Your Friends Get</div>
            <div className={classes.discountNumber}>25</div>
            <div className={classes.cardSmallText}>
              <div className={classes.cardRightTextTop}>% DISCOUNT</div>
              <div className={classes.cardVerySmallText}>On their trading fees</div>
            </div>
          </Card>
        </div>
       
        <div className={classes.moreDetails}>For more details on the Switcheo Referral Program, check out our <a href={'#faq-section'}>FAQ</a> below and <a href='#tnc-section'>Terms & Conditions.</a></div>

        <div className={classes.textMain}>
          LET'S GET <b>STARTED</b>
        </div>
        <div className={classes.textSubMain}>
          There’s just 3 simple steps to start earning referral rewards:
        </div>¯
        <div className={classes.cards}>
          <MediumCard howImage={HowConnect} header="Step 1" cta="Open a Switcheo Porfolio"
                      des={<div>Login to Switcheo to access or <a href='https://intercom.help/switcheonetwork/en/articles/3429193-create-a-switcheo-portfolio'>Create Your Portfolio</a></div>} classes={classes} />
          <MediumCard howImage={HowTell} header="Step 2" cta="Tell Your Friends" des="Share the referral code found in your Portfolio" classes={classes} />
          <MediumCard howImage={HowEarn} header="Step 3" cta="Start Getting Rewarded" des="Bonuses will automatically be awarded and tracked in your Portfolio" classes={classes} />
        </div>
        <a href="https://switcheo.exchange" target="_blank" rel="noopener noreferrer" className={classes.codeButton}>Invite Now</a>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  main: {
    width: '100vw',
    maxWidth: '100%',
    marginTop: '5rem',
    position: 'relative',
    paddingBottom: '1rem',
    '@media (max-width: 979px)': {
      marginTop: 0,
    },
  },
  background: {
    width: '100vw',
    maxWidth: '100%',
    height: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  container: {
    width: 1320,
    margin: '2rem auto 1rem auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    color: theme.fontLight,
    alignItems: 'center',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '90vw',
    },
  },
  discountCard: {
    height: '22rem',
    position: 'relative',
    width: '119rem',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: '109rem',
    },
    '@media (max-width: 979px)': {
      width: '90vw',
      height: '34rem',
    },
  },
  card: {
    width: 620,
    boxShadow: '0 12px 24px 0 rgba(70, 87, 130, 0.15)',
    zIndex: 3,
    color: theme.fontDark,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1,
    padding: '5rem 1rem',
    boxSizing: 'border-box',
    position: 'absolute',
    height: '17rem',
    top: 0,
    left: 0,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 520,
    },
    '@media (max-width: 979px)': {
      width: '85vw',
      padding: '5rem 1rem',
    },
  },
  cardTwo: {
    composes: '$card',
    // position: 'absolute',
    left: 'initial',
    width: 620,
    top: '4rem',
    right: 0,
    zIndex: 2,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 520,
    },
    '@media (max-width: 979px)': {
      marginTop: '12rem',
      width: '85vw',
      zIndex: 3,
    },
  },
  cardHeader: {
    position: 'absolute',
    top: '-1.9rem',
    background: '#96abeb',
    width: '28rem',
    height: '4.2rem',
    borderRadius: 4,
    fontSize: '2.5rem',
    fontWeight: 600,
    color: theme.fontLight,
    letterSpacing: 0.4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 979px)': {
      fontSize: '2rem',
      width: '26rem',
      height: '3.2rem',
      top: '-1.7rem',
    },
  },
  cardHeaderTwo: {
    composes: '$cardHeader',
    background: '#87d0c1',
  },
  discountNumber: {
    fontSize: '7.2rem',
    fontWeight: 600,
    '@media (max-width: 979px)': {
      fontSize: '6.4rem',
    },
  },
  cardSmallText: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginLeft: '1rem',
  },
  cardRightTextTop: {
    fontWeight: 600,
    fontSize: '3rem',
    letterSpacing: 1,
  },
  disclaimer: {
    marginTop: '2rem',
    color: theme.fontDark,
    letterSpacing: 0.18,
  },
  textMain: {
    fontSize: '3.2rem',
    textTransform: 'uppercase',
    marginTop: '14rem',
    color: theme.fontDark,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      marginTop: '14rem',
    },
    '@media (max-width: 979px)': {
      marginTop: '8rem',
    },
  },
  textSubMain: {
    fontSize: '1.9rem',
    letterSpacing: 0.2,
    color: theme.fontDark,
    marginTop: '1.5rem',
  },
  textSubMainBottom: {
    composes: '$textSubMain',
    marginTop: 0,
  },
  cards: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '8rem',
    width: 1320,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      marginTop: '14rem',
      width: 980,
    },
    '@media (max-width: 979px)': {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '2rem',
      width: '90vw',
    },
  },
  smallCard: {
    width: 372,
    boxShadow: '0 12px 48px 0 rgba(34, 42, 63, 0.2)',
    color: theme.fontDark,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '5rem',
    position: 'relative',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 290,
      padding: '4rem',
    },
    '@media (max-width: 979px)': {
      marginTop: '6rem',
      width: '90vw',
      padding: '3.2rem',
    },
  },
  cardVerySmallText: {
    fontSize: '1.6rem',
    marginTop: '0.5rem',
    '@media (max-width: 979px)': {
      fontSize: '1.4rem',
    },
  },
  icon: {
    marginTop: '2rem',
    width: '14rem',
    height: 'auto',
  },
  ticketText: {
    marginTop: '3rem',
    fontSize: '2rem',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      minHeight: '4rem',
    },
  },
  divider: {
    height: '0.1rem',
    width: '100%',
    opacity: 0.5,
    border: `solid 1px ${theme.divider}`,
    margin: '3rem 0'
  },
  ticketDescription: {
    fontSize: '1.6rem',
  },
  tickerHeader: {
    backgroundImage: 'linear-gradient(281deg, #9fded1, #85c7ba)',
    color: theme.fontLight,
    position: 'absolute',
    top: '-1.8rem',
    fontSize: '1.8rem',
    fontWeight: 600,
    width: '20rem',
    borderRadius: 4,
    height: '3.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&.silver': {
      backgroundImage: 'linear-gradient(96deg, #99abcd, #a6b9de 54%, #b7caee 79%, #a6b9de)',
    },
    '&.golden': {
      backgroundImage: 'linear-gradient(96deg, #ebdc7c, #e7ca53 21%, #e7ca53 58%, #ebdc7c 85%, #e7ca53)',
    },
  },
  cardPrize: {
    borderRadius: 4,
    display: 'flex',
    background: 'none',
    position: 'relative',
    color: theme.fontLight,
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '90rem',
    margin: '8rem auto 6rem auto',
    '@media (max-width: 979px)': {
      width: '90vw',
      margin: '4rem auto 4rem auto',
      display: 'none',
    },
  },
  cardPrizeMobile: {
    backgroundImage: 'linear-gradient(45deg, #435689, #7E9DD7);',
    display: 'none',
    flexDirection: 'column',
    lineHeight: 1.7,
    marginTop: '4rem',
    position: 'relative',
    '@media (max-width: 979px)': {
      display: 'flex',
      height: '35rem',
      width: '90vw',
    },
  },
  cardPrizeMobileLocked: {
    backgroundImage: 'linear-gradient(45deg, #B4C9E7, #9EB8DE);',
    composes: '$cardPrizeMobile'
  },
  cardPrizeTitleMobile: {
    color: theme.fontLight,
    fontSize: '1.6rem',
    fontWeight: 600,
    position: 'relative',
  },
  cardPrizeTitleMobileText: {
    width: '18rem',
    letterSpacing: 0.6,
    paddingLeft: '1rem',
  },
  triangle: {
    width: '20rem',
    left: 0,
    height: 'auto',
    position: 'absolute',
  },
  cardBackground: {
    position: 'absolute',
    borderRadius: 4,
    height: 'auto',
    width: '90rem',
    boxShadow: '0 12px 24px 0 rgba(70, 87, 130, 0.15)',
  },
  cardBackgroundTwo: {
    position: 'absolute',
    borderRadius: 4,
    height: '27.3rem',
    width: '83rem',
    left: '8.8rem',
    top: '1.4rem',
    background: '#9eb8de',
  },
  cardBackgroundFour: {
    position: 'absolute',
    borderRadius: 4,
    height: '22.6rem',
    width: '76rem',
    left: '19rem',
    top: '3.6rem',
    background: '#dbe5f4',
  },
  cardBackgroundThree: {
    position: 'absolute',
    borderRadius: 4,
    height: '24.6rem',
    width: '76rem',
    left: '17.3rem',
    top: '2.7rem',
    background: '#d3e0f1',
  },
  cardBackgroundPrevious: {
    position: 'absolute',
    borderRadius: 4,
    height: '27.2rem',
    width: '83rem',
    right: '8.4rem',
    top: '1.2rem',
    background: '#9cb9e3',
    '&.isLast': {
      background: '#556392',
    },
  },
  cardBackgroundPreviousTwo: {
    position: 'absolute',
    borderRadius: 4,
    height: '24.2rem',
    width: '83rem',
    right: '9.6rem',
    top: '2.9rem',
    background: '#b2c9e9',
    '&.isLast': {
      background: '#556392',
    },
  },
  cardBackgroundPreviousThree: {
    position: 'absolute',
    borderRadius: 4,
    height: '21.4rem',
    width: '83rem',
    right: '10.7rem',
    top: '4.2rem',
    background: '#556392',
  },
  mediumCard: {
    composes: '$smallCard',
    width: 392,
    height: '50rem',
    padding: 0,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      height: '46rem',
      width: 300,
    },
    '@media (max-width: 979px)': {
      marginTop: '2rem',
      width: '90vw',
      padding: '0 2rem 3rem 2rem',
      height: 'auto',
    },
  },
  howBackground: {
    // position: 'absolute',
    top: 0,
    width: 392,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 300,
    },
    '@media (max-width: 979px)': {
      width: '90vw',
    },
  },
  cardPrizeTitle: {
    fontSize: '2.4rem',
    zIndex: 1,
    margin: '4.5rem 0 0 8rem',
    fontWeight: 600,
    letterSpacing: 1,
    '@media (max-width: 979px)': {
      margin: '1.5rem 0 0 2rem',
      fontSize: '2rem',
    },
  },
  cardPrizeTitleLocked: {
    composes: '$cardPrizeTitle',
    margin: '2rem 0 0 8rem',
  },
  cardPrizeSubTitle: {
    fontSize: '1.6rem',
    zIndex: 1,
    margin: '1rem 0 0 8rem',
    letterSpacing: '0.4px',
    '@media (max-width: 979px)': {
      fontSize: '1.4rem',
      margin: '0.6rem 0 0 1rem',
    },
  },
  cardPrizeSubTitleMobile: {
    fontSize: '1.8rem',
    // textAlign: 'left',
    // marginLeft: '1rem',
    padding: '0 2rem',
  },
  cardPrizeMain: {
    fontSize: '6rem',
    zIndex: 1,
    margin: '1rem 0 0 8rem',
    fontWeight: 600,
    alignItems: 'center',
    display: 'flex',
    '@media (max-width: 979px)': {
      fontSize: '2.6rem',
      margin: '0.6rem 0 0 2rem',
    },
  },
  cardPrizeMainMobile: {
    zIndex: 1,
    fontWeight: 600,
    alignItems: 'center',
    display: 'flex',
    fontSize: '3.4rem',
    padding: '0 2rem',
    justifyContent: 'center',
    margin: '0 0 1rem -2rem',
  },
  bitcoin: {
    margin: '0 2rem 0 0',
    '@media (max-width: 979px)': {
      width: 26,
      height: 26,
    },
  },
  prizePeriod: {
    opacity: 0.6,
    color: '#f8f9fb',
    fontSize: '1.4rem',
    margin: '1.5rem 0 0 8rem',
    '@media (max-width: 979px)': {
      position: 'absolute',
      top: '0.6rem',
      right: 10,
      fontSize: '1.2rem',
    },
  },
  prizePeriodMobile: {
    opacity: 0.6,
    color: '#f8f9fb',
    fontSize: '1.4rem',
    marginBottom: '2rem'
  },
  gift: {
    position: 'absolute',
    right: '3rem',
    top: '7rem',
    height: '14rem',
    width: 'auto',
    '@media (max-width: 979px)': {
      display: 'none',
    },
  },
  howIcon: {
    zIndex: 1,
    position: 'absolute',
    width: 'auto',
    height: '13rem',
    marginTop: '6rem',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      marginTop: '5rem',
      height: '11rem',
    },
    '@media (max-width: 979px)': {
      marginTop: '5rem',
      height: 'auto',
      width: '52vw',
    },
  },
  // howTextFocus: {
  //   color: '#65aa88',
  // },
  // howText: {
  //   marginTop: '3rem',
  //   fontSize: '2.5rem',
  //   fontWeight: 600,
  //   letterSpacing: 1,
  //   '@media (min-width: 980px) and (max-width: 1439px)': {
  //     fontSize: '2.3rem',
  //   },
  //   '@media (max-width: 979px)': {
  //     marginTop: '2rem',
  //   },
  // },
  howContent: {
    padding: '1.2rem 3rem',
    '@media (max-width: 979px)': {
      padding: '0 2rem',
    },
  },
  howHeader: {
    fontSize: '1.5rem',
    margin: '0.5rem',
    color: '#65aa88'
  },
  howCta: {
    fontSize: '2rem',
    margin: '0.5rem',
    fontWeight: '600',
  },
  howDescription: {
    fontSize: '2rem',
    marginTop: '2rem',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '1.8rem',
    },
  },
  getStarted: {
    display: 'flex',
    marginTop: '12rem',
    boxShadow: '0 24px 48px 0 rgba(34, 42, 63, 0.2)',
    borderRadius: 4,
    '@media (max-width: 979px)': {
      marginTop: '4rem',
    },
  },
  getStartedContent: {
    flexGrow: '1',
    background: '#fff',
    color: theme.fontDark,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    padding: '6rem',
    borderRadius: 4,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      padding: '4rem',
    },
    '@media (max-width: 979px)': {
      padding: '2rem',
      width: '90vw',
    },
  },
  getStartedHeader: {
    textTransform: 'uppercase',
    fontSize: '3rem',
    fontWeight: 600,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '2.6rem',
    },
    '@media (max-width: 979px)': {
      fontSize: '2rem',
    },
  },
  getStartedText: {
    width: '66rem',
    fontSize: '1.8rem',
    textAlign: 'left',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '1.6rem',
      width: '61rem',
    },
    '@media (max-width: 979px)': {
      width: '90vw',
      fontSize: '1.6rem',
      marginTop: '2rem',
    },
  },
  graphics: {
    height: '38rem',
    width: 'auto',
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      height: '28.2rem',
    },
    '@media (max-width: 979px)': {
      display: 'none',
    },
  },
  useSwitcheo: {
    color: theme.fontHighlight,
    fontWeight: 600,
  },
  codeButton: {
    backgroundImage: 'linear-gradient(76deg, #65aa88, #7cc0d3)',
    color: theme.fontLight,
    width: '24rem',
    height: '5rem',
    borderRadius: 4,
    fontSize: '1.8rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 600,
    cursor: 'pointer',
    letterSpacing: 0.6,
    textDecoration: 'none',
    margin: '6rem auto 0 auto',
    '@media (max-width: 979px)': {
      width: '18rem',
      height: '4.2rem',
    },
  },
  singleRocket: {
    width: '38vw',
    height: 'auto',
    margin: '4rem auto',
  },
  next: {
    position: 'absolute',
    top: '13rem',
    right: '-12rem',
    cursor: 'pointer',
    '&.cardIndex': {
      cursor: 'default',
      opacity: 0.5,
    },
  },
  nextReverse: {
    position: 'absolute',
    top: '13rem',
    left: '-12rem',
    cursor: 'pointer',
    transform: 'rotate(180deg)',
    '&.cardIndex': {
      cursor: 'default',
      opacity: 0.5,
    },
  },
  left: {
    position: 'absolute',
    height: '3.4rem',
    opacity: 0.8,
    top: '16rem',
    left: '1.8rem',
    '&.cardIndex': {
      opacity: 0.4,
    },
    '@media (max-width: 979px)': {
      height: '2.4rem',
      top: '12rem',
    },
  },
  right: {
    position: 'absolute',
    height: '3.4rem',
    opacity: 0.8,
    top: '16rem',
    right: '1.8rem',
    '&.cardIndex': {
      opacity: 0.4,
    },
    '@media (max-width: 979px)': {
      height: '2.4rem',
      top: '12rem',
    },
  },
  locked: {
    zIndex: 1,
    marginTop: '7rem',
    marginLeft: '22rem',
    height: '8rem',
    width: 'auto',
  },
  mobileLocked: {
    height: '6rem',
    width: 'auto',
    marginTop: '1rem',
  },
  lockContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  moreDetails: {
    color: '#3a4873',
    marginTop: '4rem',
    fontSize: '1.3rem',
  },
})

export default Third