import React from 'react'
import theme from '../../theme'
import { ReactComponent as Background } from '../../assets/top_white_bg.svg'
import { createUseStyles } from 'react-jss'
import { ReactComponent as Simple } from '../../assets/simple.svg'
import { ReactComponent as Portfolio } from '../../assets/portfolio.svg'
import { ReactComponent as NonCustody } from '../../assets/noncustody.svg'
import { ReactComponent as Graphics } from '../../assets/graphics.svg'

const text = {
  header: 'ABOUT ',
  headerHighlight: 'SWITCHEO',
  para: 'Switcheo is a world-class preferred exchange to securely buy your favourite cryptocurrencies. Trading is available on Ethereum, EOS, and NEO blockchains.',
}

function Second(props) {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <Background className={classes.background} />
      <div className={classes.container}>
        <div className={classes.header}>
          <span>{text.header}</span>
          <span className={classes.headerBold}>{text.headerHighlight}</span>
        </div>
        <div className={classes.para}>{text.para}</div>
        <div className={classes.features}>
          <div className={classes.feature}>
            <Simple className={classes.featureIcon}/>
            <div className={classes.featureTitle}>Simple & Easy to Use</div>
            <div className={classes.featureDescription}>Create a Switcheo Account or connect your blockchain wallets to start trading.</div>
          </div>
          <div className={classes.feature}>
            <Portfolio className={classes.featureIcon}/>
            <div className={classes.featureTitle}>Manage Your Portfolio</div>
            <div className={classes.featureDescription}>Track your trades, bonuses, and referral status all in one place.</div>
          </div>
          <div className={classes.feature}>
            <NonCustody className={classes.featureIcon}/>
            <div className={classes.featureTitle}>Secure & Non-Custodial</div>
            <div className={classes.featureDescription}>Always be in full control of your own funds.</div>
          </div>
        </div>
        <div className={classes.getStarted}>
          <div className={classes.getStartedContent}>
            <div className={classes.getStartedHeader}>INVITE YOUR FRIENDS TO EARN MORE</div>
            <div className={classes.getStartedText}>Earn 50% bonus of each friend’s trading fees for sharing about Switcheo.</div>
            <a href="https://switcheo.network/referral" target="_blank" rel="noopener noreferrer" className={classes.codeButton}>Learn More</a>
          </div>
          <Graphics className={classes.graphics} />
        </div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  main: {
    width: '100vw',
    maxWidth: '100%',
    position: 'relative',
    zIndex: 0,
    paddingBottom: '1rem',
    marginTop: '-3vw',
  },
  container: {
    width: 1320,
    margin: '0 auto 3rem auto',
    zIndex: 2,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '95vw',
      margin: '0 auto 1rem auto',
    },
  },
  header: {
    fontSize: '3.2rem',
    letterSpacing: 0.4,
    color: theme.fontDark,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      paddingTop: '6rem',
    },
  },
  headerBold: {
    fontWeight: 800,
  },
  para: {
    fontSize: '1.9rem',
    color: '#3a4873',
    marginTop: '2rem',
  },
  background: {
    width: '100vw',
    maxWidth: '100%',
    height: 'auto',
    position: 'absolute',
    top: '-10vw',
    left: 0,
    zIndex: -1,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      top: '-5vw',
    },
    '@media (max-width: 979px)': {
      top: '-10rem',
    },
  },
  features: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '6rem',
    color: theme.fontDark,
    '@media (max-width: 979px)': {
      flexDirection: 'column',
      alignContent: 'center',
      justifyContent: 'center',
      marginTop: 0,
    },
  },
  feature: {
    width: '30%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 979px)': {
      width: '50vw',
      margin: '4rem auto 0 auto',
    },
  },
  featureTitle: {
    marginTop: '1.8rem',
    fontWeight: 600,
    fontSize: '2.2rem',
    '@media (max-width: 979px)': {
      fontSize: '1.8rem',
    },
  },
  featureDescription: {
    marginTop: '0.8rem',
    fontSize: '1.6rem',
    lineHeight: 1.6,
    maxWidth: '28rem',
  },
  getStarted: {
    display: 'flex',
    marginTop: '12rem',
    boxShadow: '0 24px 48px 0 rgba(34, 42, 63, 0.2)',
    borderRadius: 4,
    '@media (max-width: 979px)': {
      marginTop: '4rem',
    },
  },
  getStartedContent: {
    flexGrow: '1',
    background: '#fff',
    color: theme.fontDark,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    padding: '6rem',
    borderRadius: 4,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      padding: '4rem',
    },
    '@media (max-width: 979px)': {
      padding: '2rem',
      width: '90vw',
    },
  },
  getStartedHeader: {
    textTransform: 'uppercase',
    fontSize: '3rem',
    fontWeight: 600,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '2.6rem',
    },
    '@media (max-width: 979px)': {
      fontSize: '2rem',
    },
  },
  getStartedText: {
    width: '66rem',
    fontSize: '1.8rem',
    textAlign: 'left',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '1.6rem',
      width: '61rem',
    },
    '@media (max-width: 979px)': {
      width: '90vw',
      fontSize: '1.6rem',
      marginTop: '2rem',
    },
  },
  graphics: {
    height: '38rem',
    width: 'auto',
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      height: '28.2rem',
    },
    '@media (max-width: 979px)': {
      display: 'none',
    },
  },
  codeButton: {
    backgroundImage: 'linear-gradient(76deg, #65aa88, #7cc0d3)',
    color: theme.fontLight,
    width: '14rem',
    height: '5rem',
    fontSize: '1.6rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'none',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      fontSize: '1.4rem',
      height: '4rem',
    },
    '@media (max-width: 979px)': {
      fontSize: '1.4rem',
      height: '4rem',
      marginTop: '2rem',
    },
  },
  featureIcon: {
    width: '8rem',
    height: 'auto',
    '@media (max-width: 979px)': {
      width: '4rem',
    },
  },
})

export default Second