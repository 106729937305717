import React from 'react'
import theme from '../../theme'
import { createUseStyles } from 'react-jss'

const text = {
  header: 'REWARDS FOR ',
  headerHighlight: 'YOU & YOUR FRIENDS',
  para: 'Enjoy bonus off your friends’ trading fees, while they get to enjoy trading discounts for 3 months!',
}

function Second(props) {
  const classes = useStyles()
  return (
    <div className={classes.main}>
      <div className={classes.container}>
        <div className={classes.header}>
          <span>{text.header}</span>
          <span className={classes.headerBold}>{text.headerHighlight}</span>
        </div>
        <div className={classes.para}>{text.para}</div>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  main: {
    width: '100vw',
    maxWidth: '100%',
    position: 'relative',
    zIndex: 0,
  },
  container: {
    width: 1320,
    margin: '3rem auto',
    color: theme.fontDark,
    zIndex: 2,
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '95vw',
    },
  },
  header: {
    fontSize: '3.2rem',
    paddingTop: '4rem',
  },
  headerBold: {
    fontWeight: 800,
  },
  para: {
    fontSize: '1.9rem',
    marginTop: '1.5rem',
  },
  background: {
    width: '100vw',
    maxWidth: '100%',
    height: 'auto',
    position: 'absolute',
    top: '-20rem',
    left: 0,
    zIndex: -1,
    '@media (max-width: 979px)': {
      top: '-10rem',
    },
  },
})

export default Second