import React from 'react'
import theme from '../theme'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'

function Card(props) {
  const classes = useStyles()
  const { children, className } = props
  return (
    <div className={classNames(classes.container, className)}>
      {children}
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    background: theme.cardBackground,
    borderRadius: 4,
  },
})

export default Card