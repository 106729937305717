import React, { useState } from 'react'
import theme from '../../theme'
import { createUseStyles } from 'react-jss'
import { ReactComponent as Background } from '../../assets/top-bg.svg'
import Card from '../Card'

function Top(props) {
  const classes = useStyles()
  const [code, setCode] = useState(props.id)
  function handleChange(e) {
    setCode(e.target.value)
  }
  const link = `https://switcheo.exchange?referral_code=${code}`
  return (
    <div className={classes.main}>
      <Background className={classes.background} />
      <div className={classes.container}>
        <Card className={classes.card}>
          <div className={classes.header}>You’ve been invited to trade on Switcheo!</div>
          <div className={classes.title}><b>25%</b> Discount</div>
          <div className={classes.titleSupportingText}>on your trading fees.*</div>
          <div className={classes.joinNow}>
            <input type="text" className={classes.referralCode} value={code} onChange={handleChange}/>
            <a className={classes.button} href={link} target="_blank" rel="noopener noreferrer">Join Now</a>
          </div>
          <div className={classes.tandc}><sup>*</sup>Limited to first 3 months of trade. <a href="https://switcheo.network/pdf/referral-terms.pdf" target = "_blank" rel="noopener noreferrer" >Terms and Conditions apply.</a></div>
        </Card>
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  background: {
    width: '100vw',
    maxWidth: '100%',
    height: 'auto',
    '@media (max-width: 979px)': {
      height: '22rem',
    },
  },
  main: {
    position: 'relative',
    width: '100vw',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 0,
    '@media (max-width: 979px)': {
      height: '54rem',
      background: '#51608C',
    },
  },
  container: {
    position: 'absolute',
    width: 1320,
    top: 0,
    margin: '8vw auto 4rem auto',
    color: theme.fontLight,
    display: 'flex',
    justifyContent: 'space-around',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '95vw',
      margin: '8rem auto 4rem auto',
    },
  },
  card: {
    width: 1320,
    display: 'flex',
    flexDirection: 'column',
    color: theme.fontDark,
    background: '#f5f8ff',
    alignItems: 'center',
    '@media (min-width: 980px) and (max-width: 1439px)': {
      width: 980,
    },
    '@media (max-width: 979px)': {
      width: '95vw',
    },
  },
  header: {
    marginTop: '3.4rem',
    fontSize: '1.9rem',
    fontWeight: 600,
    color: '#8895b7',
    letterSpacing: 0.4,
  },
  title: {
    fontSize: '4.8rem',
    textTransform: 'uppercase',
  },
  referralCode: {
    flexGrow: 1,
    fontSize: '1.6rem',
    background: '#fff',
    height: '3.8rem',
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    border: 0,
    textAlign: 'center',
    outline: 'none',
  },
  joinNow: {
    display: 'flex',
    border: '1px solid #65aa88',
    width: '58rem',
    alignItems: 'center',
    borderRadius: 4,
    marginTop: '3.8rem',
    cursor: 'pointer',
    '@media (max-width: 979px)': {
      width: '88vw',
    },
  },
  button: {
    backgroundImage: 'linear-gradient(76deg, #65aa88, #7cc0d3)',
    color: theme.fontLight,
    height: '3.8rem',
    width: '12rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.6rem',
    fontWeight: 600,
    textDecoration: 'none',
  },
  tandc: {
    marginTop: '1rem',
    marginBottom: '3.4rem',
    fontSize: '1.4rem',
    '@media (max-width: 979px)': {
      width: '84vw',
    },
  },
  titleSupportingText: {
    fontSize: '1.4rem',
  },
})

export default Top