const BLACK = '#3a4873'
const WHITE = '#fff'
const GREEN = '#88c773'
const BLUE_PALE = '#f5f8ff'
const BLUE = '#8895b7'

const theme = {
  fontDark: BLACK,
  fontLight: WHITE,
  fontHighlight: GREEN,
  background: BLUE_PALE,
  cardBackground: WHITE,
  divider: BLUE,
}

export default theme