import React from 'react'
import './App.css'
import Layout from './components/Layout'
import Home from './components/landing-page/Home'
import ReferredBy from './components/referred-by/ReferredBy'
import queryString from 'query-string'

function App() {
  const values = queryString.parse(window.location.search)
  if ("referred_by" in values) {
    return (
      <div className="App">
        <Layout>
          <ReferredBy id={values.referred_by} />
        </Layout>
    </div>
    )
  }
  return (
    <div className="App">
      <Layout>
        <Home />
      </Layout>
    </div>
  );
}

export default App;
